// NotFoundPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from './MainLayout/Header';
import { getUserAgentInfo } from '../utils';
import sendLogToCloudWatch from '../services/logger';

function NotFoundPage() {
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate()
  const userAgentInfo = getUserAgentInfo();
    const browser = userAgentInfo.browser.name || 'desconhecido';
    const os = userAgentInfo.os.name || 'desconhecido';

  sendLogToCloudWatch('info',`O usuário ${user.username} está usando o ${browser} e o sistema operacional ${os} e caiu numa rota não encontrada.`);

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className='page-content'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <h2>Página não encontrada</h2>
        <p>Sinto muito, parece que essa página não está disponível. Navegue até outra página ou volte mais tarde.</p>
      </div>
    </div>
  );
}

export default NotFoundPage;
