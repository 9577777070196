import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Popper,
    Typography,
    Grid2,
    Paper,
    ClickAwayListener,
    Divider,
    CardActions,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Backdrop
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Collapse from '@mui/material/Collapse';
import { format, parseISO, isBefore, addDays } from 'date-fns';
import sendLogToCloudWatch from '../../services/logger';

const NotificationSection = ({ onExpiringSoonChange }) => {
    const user = useSelector(state => state.auth.user);
    const [open, setOpen] = useState(false);
    const [certificates, setCertificates] = useState([]);
    const anchorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (user && user.customer_id) {
            fetchCertificates();
        }
    }, [user?.customer_id, onExpiringSoonChange]);

    const fetchCertificates = async () => {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_API_URL}/v1/certificates/customer/${user.customer_id}`;
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: { "Content-Type": "application/json" },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            
            const now = new Date();
            const thirtyDaysFuture = addDays(now, 30);

            const validCertificates = data.filter(certificate => 
                certificate.status === 'Valid' &&
                isBefore(new Date(), parseISO(certificate.expiration_date)) &&
                isBefore(parseISO(certificate.expiration_date), thirtyDaysFuture)
            );
            
            const sortedCertificates = validCertificates.sort((a, b) =>
                new Date(a.expiration_date) - new Date(b.expiration_date)
            ).slice(0, 3);
            setCertificates(sortedCertificates);
            onExpiringSoonChange(sortedCertificates.length > 0);
        } catch (error) {
            sendLogToCloudWatch('error','Failed to fetch certificates:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleViewAllClick = () => {
        if (location.pathname === '/') {
            handleClose();
        } else {
            navigate('/');
        }
    };

    const getExpirationColor = (date) => {
        const now = new Date();
        const daysToExpire = (new Date(date) - now) / (1000 * 60 * 60 * 24);
        if (daysToExpire <= 7) return '#ff1744'; // red
        if (daysToExpire <= 15) return '#ff9100'; // orange
        if (daysToExpire <= 30) return '#ffc107'; // yellow
        return 'inherit'; // default
    };

    return (
        <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
        />
        <Box sx={{ ml: 'auto', mr: 3 }}>
            <ButtonBase sx={{ borderRadius: '12px' }} onClick={handleToggle}>
                <Avatar
                    variant="rounded"
                    sx={{
                        borderRadius: '12px',
                        overflow: 'hidden',
                        transition: 'all .2s ease-in-out',
                        backgroundColor: '#003251',
                    }}    
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    color="inherit"
                >
                    {certificates.length > 0 ? (
                        <NotificationImportantIcon className="header-icon" stroke={1.5} size="1.3rem" />
                    ) : (
                        <NotificationsIcon className="header-icon" stroke={1.5} size="1.3rem" />
                    )}
                </Avatar>
            </ButtonBase>
        </Box>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            onClose={handleClose}
            style={{ zIndex: 1301 }}
        >
            <Collapse in={open}>
                <Paper elevation={16} style={{ maxWidth: 300, overflow: 'auto' }}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box sx={{ pt: 2, px: 2, pb: 2 }}>
                            <Typography variant="subtitle1" color="primary">Avisos</Typography>
                            {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : certificates.length > 0 ? (
                                <List>
                                    {certificates.map(certificate => (
                                        <ListItem key={certificate.id}>
                                            <ListItemText
                                                primary={`Expirando em ${format(parseISO(certificate.expiration_date), 'dd/MM/yyyy')}`}
                                                secondary={`url: ${certificate.url}`}
                                                primaryTypographyProps={{ style: { color: getExpirationColor(certificate.expiration_date) } }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography>Não há certificados expirando nos próximos 30 dias.</Typography>
                            )}
                            <Divider />
                            <CardActions sx={{ justifyContent: 'center', pt: 2 }}>
                                <Button size="small" onClick={handleViewAllClick} disableElevation>Ver tudo</Button>
                            </CardActions>
                        </Box>
                    </ClickAwayListener>
                </Paper>
            </Collapse>
        </Popper>
    </>
);
};

export default NotificationSection;
