import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Typography, CircularProgress, Container } from '@mui/material';
import Header from '../MainLayout/Header';
import Sidebar from '../MainLayout/Sidebar';

function FileConverter() {
  const user = useSelector((state) => state.auth.user);
  const [file, setFile] = useState(null);
  const [format, setFormat] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFormatChange = (event) => {
    setFormat(event.target.value);
  };

  const handleSubmit = async () => {
    if (!file || !format) {
      setMessage('Por gentileza, selecione um arquivo e o formato para conversão.');
      return;
    }

    setLoading(true);
    setMessage('');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('format', format);

    try {
      const response = await fetch('/api/convert', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `converted_file.${format}`; // Assume the format is the file extension
        document.body.appendChild(a);
        a.click();
        a.remove();
        setMessage('Arquivo convertido e download iniciado!');
      } else {
        throw new Error('Falha na conversão do arquivo.');
      }
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Sidebar />
      <Box className="page-content">
        <Container component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>Converter arquivo</Typography>

          <FormControl fullWidth sx={{ mb: 2, paddingTop: 5 }}>
            <InputLabel id="file-upload-label">Importe um arquivo (.pfx, .pem, .cer, .crt)</InputLabel>
            <input
              type="file"
              onChange={handleFileChange}
              accept=".pfx,.pem,.cer,.crt"
              style={{ padding: 10 }}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="format-label">Converter ao formato</InputLabel>
            <Select
              labelId="format-label"
              id="format-select"
              value={format}
              label="Converter ao formato"
              onChange={handleFormatChange}
            >
              <MenuItem value="pfx">.pfx</MenuItem>
              <MenuItem value="pem">.pem</MenuItem>
              <MenuItem value="cer">.cer</MenuItem>
              <MenuItem value="cer">.crt</MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Arquivo Convertido'}
          </Button>

          {message && (
            <Typography color="primary" sx={{ mt: 2 }}>
              {message}
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
}

export default FileConverter;
