import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import sendLogToCloudWatch from '../../services/logger';

function AddCustomerForm({ open, onClose, onSuccess }) {
  const logger = require('../../services/logger');
  const initialFormData = {
    name: '',
    nickname: '',
    phone: '',
    cnpj: '',
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAdd = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        // A conta foi criada com sucesso
        sendLogToCloudWatch('info','Conta criada com sucesso');
        onSuccess(); // Notify the parent component of success
        setFormData({ ...initialFormData }); // Clear the form data

      } else {
        // A criação da conta falhou
        const responseData = await response.json();
        setError(responseData.error || 'Erro desconhecido');
      }
    } catch (error) {
      // Erro de rede ou outro erro
      setError('Erro de rede. Tente novamente mais tarde.');
      
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Conta</DialogTitle>
      <DialogContent>
        <TextField
          name="name"
          label="Nome Empresarial"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required // Campo obrigatório
        />
        <TextField
          name="nickname"
          label="Nome Fantasia"
          value={formData.nickname}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required // Campo obrigatório
        />
        <TextField
          name="phone"
          label="Telefone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="cnpj"
          label="CNPJ"
          value={formData.cnpj}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleAdd} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Adicionar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCustomerForm;
