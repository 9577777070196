import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginAsync } from '../../services/auth/authSlice';
import { getUserAgentInfo } from '../../utils';
import "../../styles.css"
import BackgroundVideo from './BackgroundVideo';
import { Grid2, Link, Stack, Typography, TextField, Button, Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import sendLogToCloudWatch from '../../services/logger';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [keyPressed, setKeyPressed] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoaded(true); // firula para o fade in na abertura da página

    const handleKeyPress = (event) => {
      if (event.key === 'Enter' || event.key === 'Return') {
        setKeyPressed(true);
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleLogin = () => {
    sendLogToCloudWatch('info','Attempting login with:', username);
  
    const userData = { username, password };
    const userAgentInfo = getUserAgentInfo();
    sendLogToCloudWatch('info',userAgentInfo);
    sendLogToCloudWatch('info',userData);
  
    setLoading(true); // Iniciar o carregamento
    
    dispatch(loginAsync(userData))
      .then((user) => {
        sendLogToCloudWatch('info','User after successful login:', user);
        sendLogToCloudWatch('info','User after successful login:', user);
        
        // Salvar o token de autenticação em um cookie e no localStorage
        localStorage.setItem('username', user.username);
        localStorage.setItem('token', user.token);
        localStorage.setItem('customer_id', user.customer_id);
        localStorage.setItem('isAdmin', user.isAdmin);
    
        // Redirecionar para a página principal após o login bem-sucedido
        navigate('/');
      })
      .catch((error) => {
        sendLogToCloudWatch('error','A tentativa de login falhou:', error.message);
      })
      .finally(() => {
        setLoading(false); // Finalizar o carregamento
      });
  };

  return (
    <>
      <BackgroundVideo />
      <div className={loaded ? 'fadeIn' : ''}>
      <Grid2 container direction='column' alignItems='center' justifyContent='center' sx={{ minHeight: '100vh' }}>
        <Paper elevation={3} sx={{ maxWidth: 500, m: { xs:1, sm:3 }, mb: 0, p: 3, bgcolor: 'white', borderRadius: 2 }} >
          <Grid2 container spacing={2} alignItems='center' justifyContent='center'>
            {/* Logo Section */}
            <Grid2 item xs={12} sx={{ textAlign: 'center' }}>
              <Link to='#'>
                <img src="https://www.trustcert.com.br/wp-content/uploads/2023/06/trustcert-logo.png" alt="Logo" />
              </Link>
            </Grid2>
            {/* Just a text */}
            <Grid2 item xs={12}>
              <Grid2
                container
                direction='row'
                alignItems="center"
                justifyContent="center"
              >
                <Grid2 item>
                  <Stack alignItems="center" justifyContent="center" spacing={1}>
                    <Typography>
                      Olá, seja bem-vindo
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize="16px"
                      textAlign='center'
                    >
                      Entre com suas credenciais
                    </Typography>
                  </Stack>
                </Grid2>
              </Grid2>
            </Grid2>
            {/* Login Form */}
            <Grid2 item xs={12}>
              <form noValidate autoComplete="off">
                <TextField
                  fullWidth
                  type="text"
                  label="Usuário"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete="current-username"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  type="password"
                  label="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  margin="normal"
                />
                <Button type="button" variant="contained" color="primary" onClick={handleLogin} fullWidth sx={{ mt: 2 }}>
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Entrar'}
                </Button>
              </form>
            </Grid2>
            {/* Forgot password & Create Account */}
            <Grid2 item xs={12} container justifyContent="space-between">
              <Typography>
                <a href="mailto:suporte@trustcert.com.br" style={{ textDecoration: 'none', color: 'black' }}>Esqueceu a senha?</a>
              </Typography>
              <Typography>
                <a href="mailto:suporte@trustcert.com.br?subject=Trustcert%20|%20Quero%20criar%20minha%20conta" style={{ textDecoration: 'none', color: 'black' }}>
                Não tem uma conta?
                </a>
              </Typography>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      </div>
    </>
  );
}

export default LoginPage;
