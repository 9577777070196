import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuth } from './services/auth/authSlice';
import LoginPage from './components/Authentication/LoginPage';
import DashboardPage from './components/DashboardPage/DashboardPage';
import UsersPage from './components/UsersPage/UsersPage';
import NotFoundPage from './components/NotFoundPage';
import GenerateCSR from './components/RequestPage/GenerateCSR';
import CertificatesRequest from './components/RequestPage/CertificatesRequest';
import CustomerPage from './components/CustomersPage/CustomerPage';
import ChangePassword from './components/UsersPage/changePassword';
import FileConverter from './components/ToolsMenu/FileConverter';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Configuração do tema MUI
const theme = createTheme({
  palette: {
    primary: {
      main: '#003251', // Cor primária
    },
    secondary: {
      main: '#1976D2', // Cor secundária
    },
  },
});

function App() {
  const isRehydrated = useSelector((state) => state._persist.rehydrated);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isRehydrated) return;
    // Verifica a autenticação na inicialização do componente App
    dispatch(checkAuth());
  }, [dispatch, isRehydrated]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {/* Rota condicional baseada no estado de autenticação */}
        {isAuthenticated ? (
          // Usuário autenticado: renderiza rotas protegidas
          <>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/customers" element={<CustomerPage />} />
            <Route path="/generate" element={<GenerateCSR />} />
            <Route path="/request" element={<CertificatesRequest />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/generate-csr" element={<GenerateCSR />} />
            <Route path="/file-converter" element={<FileConverter />} />
          </>
        ) : (
          // Usuário não autenticado: redireciona para a página de login
          <Route path="*" element={<Navigate to="/login" />} />
        )}
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
