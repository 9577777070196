import { CloudWatchLogsClient, PutLogEventsCommand } from '@aws-sdk/client-cloudwatch-logs';

const cloudWatchLogs = new CloudWatchLogsClient({
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const logGroupName = 'mpki-console-log-group';
const logStreamName = 'mpki-console-log-stream';

async function sendLogToCloudWatch(level, message) {
  const params = {
    logGroupName,
    logStreamName,
    logEvents: [
      {
        timestamp: Date.now(),
        message: `[${level.toUpperCase()}] - ${message}`,
      },
    ],
  };

  try {
    const command = new PutLogEventsCommand(params);
    await cloudWatchLogs.send(command);
    console.log("Log enviado para CloudWatch:", message);
  } catch (error) {
    console.error("Erro ao enviar log para CloudWatch:", error);
  }
}

// Exporte a função como padrão
export default sendLogToCloudWatch;
