import React, { useState } from 'react';
import { Button, TextField, Box, IconButton, Tooltip, FormControl, Snackbar, Typography } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import * as forge from 'node-forge';
import Header from '../MainLayout/Header';
import Sidebar from '../MainLayout/Sidebar';
import sendLogToCloudWatch from '../../services/logger';

const GenerateCSR = () => {
  // State for form inputs
  const [commonName, setCommonName] = useState('');
  const [organization, setOrganization] = useState('');
  const [department, setDepartment] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');

  // State for results
  const [csr, setCsr] = useState('');
  const [privateKey, setPrivateKey] = useState('');

  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);

  const generateCSR = async () => {
    if (!commonName || !organization || !department || !city || !province || !country) {
      setSnackbarMessage("Por gentileza, preencher todos os campos.");
      setSnackbarOpen(true);
      return;
    }
  
    try {
      // Generate a key pair asynchronously
      forge.pki.rsa.generateKeyPair({ bits: 2048, workers: 2 }, function(err, keys) {
        if (err) {
          setSnackbarMessage("Falha ao gerar a Chave.");
          setSnackbarOpen(true);
          return;
        }
  
        const cert = forge.pki.createCertificationRequest();
        cert.publicKey = keys.publicKey;
        cert.setSubject([
          { name: 'commonName', value: commonName },
          { name: 'organizationName', value: organization },
          { name: 'organizationalUnitName', value: department },
          { name: 'localityName', value: city },
          { name: 'stateOrProvinceName', value: province },
          { name: 'countryName', value: country },
        ]);
        cert.sign(keys.privateKey);
  
        const pem = forge.pki.certificationRequestToPem(cert);
        setCsr(pem);
  
        const privateKeyPem = forge.pki.privateKeyToPem(keys.privateKey);
        setPrivateKey(privateKeyPem);
  
        setSnackbarMessage("CSR e Chave Privada gerados com sucesso.");
        setSnackbarOpen(true);
      });
    } catch (error) {
      setSnackbarMessage("Falha ao gerar CSR.");
      setSnackbarOpen(true);
    }
  };
  

  const copyCSRToClipboard = () => {
    navigator.clipboard.writeText(csr).then(() => {
      setCopySuccess(true);
    }).catch((err) => {
      sendLogToCloudWatch('error', 'Failed to copy CSR to clipboard: ', err);
    });
  };

  const downloadPrivateKey = () => {
    const blob = new Blob([privateKey], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'server.key';
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Header />
      <Sidebar />
      <Box className='page-content'>
        <Typography variant="h4">Gerar CSR</Typography>
        <TextField
          name="commonName"
          label="Common Name (URL)"
          value={commonName}
          fullWidth
          margin="normal"
          onChange={(e) => setCommonName(e.target.value)}
        />
        <TextField
          name="organization"
          label="Organization Name (Nome da Empresa)"
          value={organization}
          fullWidth
          margin="normal"
          onChange={(e) => setOrganization(e.target.value)}
        />
        <TextField
          name="department"
          label="Department (Departamento)"
          value={department}
          fullWidth
          margin="normal"
          onChange={(e) => setDepartment(e.target.value)}
        />
        <TextField
          name="city"
          label="City (Cidade)"
          value={city}
          fullWidth
          margin="normal"
          onChange={(e) => setCity(e.target.value)}
        />
        <TextField
          name="province"
          label="State / Province (UF)"
          value={province}
          fullWidth
          margin="normal"
          onChange={(e) => setProvince(e.target.value)}
        />
        <TextField
          name="country"
          label="Country (País)"
          value={country}
          fullWidth
          margin="normal"
          onChange={(e) => setCountry(e.target.value)}
        />
        <Button color="primary" variant="contained" onClick={generateCSR}>
          Gerar CSR e Chave Privada
        </Button>

        {csr && privateKey && (
          <>
            <Typography variant="h6">Aqui está a sua CSR:</Typography>
            <FormControl fullWidth>
              <TextField
                value={csr}
                multiline
                rows={5}
                fullWidth
              />
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'left', mt: 2 }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={downloadPrivateKey}
              >
                Baixar minha Chave Privada
              </Button>
              <Tooltip title={copySuccess ? 'Copiar' : 'CSR pronta para o Ctrl+V'}>
                <IconButton
                  color="primary"
                  onClick={copyCSRToClipboard}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>

            {/* Snackbar para mensagens de sucesso/erro */}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={snackbarMessage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default GenerateCSR;
