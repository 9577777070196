//DashboardTable
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import sendLogToCloudWatch from '../../services/logger';
import { Grid2, TextField, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, IconButton, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ButtonGroup, Button, Snackbar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

import '../../styles.css';
import Upload from './UploadCerificates';
import DownloadCertificates from './DownloadCertificates';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const typeLegend = {
  291: "Teste DV (30 dias)",
  287: "DV",
  289: "Wildcard DV",
  279: "Multi-Domínio DV",
  556: "EV",
  557: "Multi-Domínio EV",
  330: "DV",
  331: "Wildcard DV",
  7: "OV",
  35: "OV Wildcard",
  361: "UCC OV",
  567: "EV",
  568: "Multi-Domínio EV",
  488: "DV",
  489: "Wildcard DV",
  492: "UCC DV",
  316: "OV",
  322: "OV Wildcard",
  583: "Multi-Domínio OV",
  337: "EV",
  410: "Multi-Domínio EV",
  749: "DV",
  750: "Wildcard DV",
  751: "Multi-Domínio DV",
  63: "OV",
  64: "Pro OV",
  65: "Pro Wildcard OV",
  335: "Pro Multi-Domínio OV",
  562: "Pro EV",
  563: "Pro Multi-Domínio EV",
  721: "Pro DV",
  722: "Pro Wildcard DV",
  723: "Pro Multi-Domínio DV",
  726: "Pro OV",
  727: "Pro OV Wildcard",
  728: "Pro OV Multi-Domínio",
  731: "Pro EV",
  732: "Pro Multi-Domínio EV",
  735: "DV",
  736: "Wildcard DV",
  737: "Multi-Domínio DV",
  740: "OV",
  741: "OV Wildcard",
  742: "OV Multi-Domínio",
  745: "EV",
  746: "Multi-Domínio EV",
  754: "DV",
  755: "Wildcard DV",
  756: "Multi-Domínio DV",
  759: "OV",
  760: "OV Wildcard",
  761: "Multi-Domínio OV",
  764: "EV",
  765: "Multi-Domínio EV",
  36: "EV or OV TrustLogo"
}

function DashboardTable(props) {
  const isRehydrated = useSelector((state) => state._persist.rehydrated);
  const user = useSelector((state) => state.auth.user);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('expiration_date');
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const allStatus = ['Valid', 'Expired', 'Rejected', 'Pending', 'Revoked', 'Awaiting+Validation+%28Full%29', 'Replaced',''];
  const validStatus = ['Valid'];

  const [filteredData, setFilteredData] = useState([]);
  const [isTableRefreshing, setIsTableRefreshing] = useState(false);
  const [excludeStatus, setExcludeStatus] = useState(allStatus.filter(status => !validStatus.includes(status)));

  const [downloadItem, setDownloadItem] = useState(null);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  useEffect(() => {
    if (!user || !user.customer_id || !isRehydrated) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/v1/certificates/customer/${user.customer_id}`;
        const response = await fetch(apiUrl, { headers: { "Content-Type": "application/json" } });
        //sendLogToCloudWatch('info',('Resposta bruta da API:', response);

        const data = await response.clone().json();
        //sendLogToCloudWatch('info',('Raw response data:', data);

        if (Array.isArray(data)) {
          const uniqueData = Array.from(new Set(data.map(item => item.id)))
                                 .map(id => data.find(item => item.id === id));
          setFilteredData(uniqueData);
        } else {
          sendLogToCloudWatch('error','A resposta não é uma matriz:', data);
        }
      } catch (error) {
        sendLogToCloudWatch('error','Erro ao buscar os certificados:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [user, isRehydrated]);

  const handleRefreshTable = async () => {
    if (!user || !user.customer_id) return;

    setIsTableRefreshing(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/certificates/customer/${user.customer_id}`;
      const response = await fetch(apiUrl, { headers: { "Content-Type": "application/json" } });
      const updatedData = await response.json();
      if (Array.isArray(updatedData)) {
        const uniqueData = Array.from(new Set(updatedData.map(item => item.id)))
                               .map(id => updatedData.find(item => item.id === id));
        setFilteredData(uniqueData);
      } else {
        sendLogToCloudWatch('error','Os dados recebidos não são uma matriz.');
      }
    } catch (error) {
      sendLogToCloudWatch('error','Erro ao atualizar a tabela:', error);
    } finally {
      setIsTableRefreshing(false);
    }
  };

  const createSortHandler = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCheckboxChange = (event, id) => {
    const updatedSelectedIds = new Set(selectedIds);
    if (event.target.checked) {
      updatedSelectedIds.add(id);
      sendLogToCloudWatch('info','Selecting order with id:', id);
    } else {
      updatedSelectedIds.delete(id);
    }
    setSelectedIds(updatedSelectedIds);
  }; 

  const toggleValidStatus = () => {
    setExcludeStatus((currentStatus) => {
      if (currentStatus.length === allStatus.length - validStatus.length) {
        return [];
      } else {
        return allStatus.filter(status => !validStatus.includes(status));
      }
    });
  };

  const formatStatus = (status) => {
    if (status === 'Awaiting+Validation+%28Full%29') {
      return 'Aguardando Validação';
    }
    if (status === 'Valid') {
      return 'Válido';
    }
    if (status === 'Expired') {
      return 'Expirado';
    }
    if (status === 'Rejected') {
      return 'Rejeitado';
    }
    if (status === 'Pending') {
      return 'Pendente';
    }
    if (status === 'Revoked') {
      return 'Revogado';
    }
    if (status === 'Replaced') {
      return 'Substituído';
    } 
    if (status === '') {
      return 'Indisponível';
    } 
    return status;
  };
  
  const sortedData = filteredData.slice().sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (aValue === undefined || bValue === undefined) {
      return 0;
    }

    return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });  

  const filteredAndSortedData = sortedData.filter((item) => {
    // Busca o termo em todos os campos convertidos para string
    const matchesSearchTerm = Object.values(item).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    // Verifica se o status está incluído nos excluídos
    const isExcludedStatus = excludeStatus.includes(item.status);
  
    // Retorna true apenas se o item corresponder ao termo de pesquisa e não tiver status excluído
    return matchesSearchTerm && !isExcludedStatus;
  });
  
  //sendLogToCloudWatch('info',('Filtered and Sorted Data:', filteredAndSortedData);
 

  const handleRevokeClick = (orderId) => {
    setSelectedOrderId(orderId);
    setConfirmationDialogOpen(true);
  };

  const revokeCertificate = async () => {
    if (!selectedOrderId) return;

    const payload = {
      order_number: selectedOrderId,
      customer_id: user.customer_id,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/provider/certificate/revoke`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        setMessage({ text: 'Certificado revogado com sucesso!', type: 'success' });
        // Refresh the table after revoking the certificate
        handleRefreshTable();
      } else {
        const error = await response.json();
        setMessage({ text: error.error_message || 'Erro ao revogar o certificado.', type: 'error' });
      }
    } catch (error) {
      setMessage({ text: 'Erro ao revogar o certificado.', type: 'error' });
    } finally {
      setConfirmationDialogOpen(false);
      setSelectedOrderId(null);
    }
  };

  const getCertificateTypeLegend = (type) => {
    return typeLegend[type] || "Tipo do Certificado não especificado";
  };

  const handleExportCSV = () => {
    const csvRows = [
        // headers
        ['Order Number', 'Domain', 'Type of Certificate', 'Status', 'Started at', 'Expire at'],
        // data
        ...filteredData.map(item => [
            item.id,
            item.url,
            typeLegend[item.type] || "Indisponível",
            item.status,
            new Date(item.start_date).toLocaleDateString(),
            new Date(item.expiration_date).toLocaleDateString()
        ])
    ];
    const csvData = csvRows.map(e => e.join(',')).join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'certificates.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
        <TableContainer className="tableContainer">
          {/* Action Buttons */}
          <Grid2 container spacing={2} alignItems="center">
              {/* Upload Button */}
              <Grid2 item xs={2}>
                <Button startIcon={<FileUploadIcon />}>
                  <Upload />
                </Button>
              </Grid2>
              
              {/* Search Bar */}
              <Grid2 item xs={5}>
                  <TextField
                      fullWidth
                      label="Buscar"
                      variant="outlined"
                      size="small"
                      onChange={(e) => setSearchTerm(e.target.value)}
                  />
              </Grid2>
              
              {/* Filter Button */}
              <Grid2 item xs={2}>
                  <ButtonGroup variant="contained" aria-label="Filter Actions">
                      <Button
                          color={excludeStatus.length === allStatus.length - validStatus.length ? "primary" : "secondary"}
                          onClick={toggleValidStatus}
                      >
                          Filtrar Ativos
                      </Button>
                  </ButtonGroup>
              </Grid2>
              
              {/* Export Button */}
              <Grid2 item xs={2}>
                <Button onClick={handleExportCSV} startIcon={<FileDownloadIcon />}>
                    Exportar csv
                </Button>
              </Grid2>
              
              {/* Refresh Table Button */}
              <Grid2 item xs={1}>
                  <IconButton
                      color="primary"
                      aria-label="refresh"
                      onClick={handleRefreshTable}
                      disabled={isTableRefreshing}
                      size="large"
                  >
                      {isTableRefreshing ? <CircularProgress size={24} /> : <RefreshIcon />}
                  </IconButton>
              </Grid2>
          </Grid2>

          <TableContainer className="tableContainer">
            <Table className="table">

              {/* Header */}
              <TableHead>
                <TableRow>
                  {/* Select Button */}
                  <TableCell>Selecionar</TableCell>

                  {/* Download and Revoke Buttons */}
                  <TableCell>Download/Revogar</TableCell>

                  {/* Order Number Field */}
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'id'}
                      direction={order}
                      onClick={createSortHandler('id')}
                      style={{
                        color: orderBy === 'id' ? '#fff' : '#fff',
                      }}
                      classes={{
                          icon: orderBy === 'id'
                              ? order === 'desc'
                                  ? 'MuiTableSortLabel-iconDirectionDesc'
                                  : 'MuiTableSortLabel-iconDirectionAsc'
                              : '',
                      }}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>

                  {/* Domain Name Field */}
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'domain'}
                      direction={order}
                      onClick={createSortHandler('domain')}
                      style={{
                        color: orderBy === 'domain' ? '#fff' : '#fff',
                      }}
                      classes={{
                          icon: orderBy === 'domain'
                              ? order === 'desc'
                                  ? 'MuiTableSortLabel-iconDirectionDesc'
                                  : 'MuiTableSortLabel-iconDirectionAsc'
                              : '',
                      }}
                    >
                      Certificado para a url
                    </TableSortLabel>
                  </TableCell>

                  {/* Type Field */}
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'type'}
                      direction={order}
                      onClick={createSortHandler('type')}
                      style={{
                        color: orderBy === 'type' ? '#fff' : '#fff',
                      }}
                      classes={{
                          icon: orderBy === 'type'
                              ? order === 'asc'
                                  ? 'MuiTableSortLabel-iconDirectionDesc'
                                  : 'MuiTableSortLabel-iconDirectionAsc'
                              : '',
                      }}
                    >
                      Tipo do Certificado
                    </TableSortLabel>
                  </TableCell>

                  {/* Started at Field */}
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'status'}
                      directions={order}
                      onClick={createSortHandler('status')}
                      style={{
                        color: orderBy === 'status' ? '#fff' : '#fff',
                      }}
                      classes={{
                        icon: orderBy === 'status'
                            ? order === 'desc'
                                ? 'MuiTableSortLabel-iconDirectionDesc'
                                : 'MuiTableSortLabel-iconDirectionAsc'
                            : '',
                        }}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                        active={orderBy === 'start_date'}
                        direction={order}
                        onClick={createSortHandler('start_date')}
                        style={{
                          color: orderBy === 'start_date' ? '#fff' : '#fff',
                        }}
                        classes={{
                            icon: orderBy === 'start_date'
                                ? order === 'desc'
                                    ? 'MuiTableSortLabel-iconDirectionDesc'
                                    : 'MuiTableSortLabel-iconDirectionAsc'
                                : '',
                        }}
                      >
                      Válido em
                    </TableSortLabel>
                  </TableCell>

                  {/* Expire at Field */}
                  <TableCell>
                    <TableSortLabel
                        active={orderBy === 'expiration_date'}
                        direction={order}
                        onClick={createSortHandler('expiration_date')}
                        style={{
                          color: orderBy === 'Expire at' ? '#fff' : '#fff',
                        }}
                        classes={{
                            icon: orderBy === 'expiration_date'
                                ? order === 'desc'
                                    ? 'MuiTableSortLabel-iconDirectionDesc'
                                    : 'MuiTableSortLabel-iconDirectionAsc'
                                : '',
                        }}
                      >
                      Expira em
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              
              {/* Body */}
              {isLoading ? (
                  <tbody>
                      <tr>
                          <td style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(155, 155, 155, 0.7)', zIndex: 2 }}>
                              <Grid2
                                  container
                                  style={{ height: '100vh' }}
                                  alignItems="center"
                                  justifyContent="center"
                              >
                                  <CircularProgress />
                              </Grid2>
                          </td>
                      </tr>
                  </tbody>
              ) : (
              <TableBody>
                {filteredAndSortedData.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <Checkbox
                        onChange={(event) => handleCheckboxChange(event, item.id)}
                        checked={selectedIds.has(item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* button download */}
                        <DownloadCertificates 
                          isLoading={isLoading} 
                          certificateData={item} 
                          setDownloadItem={setDownloadItem} 
                        />
                        {/* button revoke */}
                        <IconButton 
                          className='revokeIcon'
                          color='error'
                          variant="contained"
                          aria-label="revoke"
                          onClick={() => handleRevokeClick(item.id)} 
                          disabled={!selectedIds.has(item.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.url === "Unknown" ? "Indisponível" : item.url}</TableCell>
                    <TableCell>{getCertificateTypeLegend(item.type)}</TableCell>
                    <TableCell>{formatStatus(item.status)}</TableCell>
                    <TableCell>{new Date(item.start_date).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(item.expiration_date).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              )}
            </Table>

            {/* Dialog when trying to revoke */}
            <Dialog open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
              <DialogTitle>Confirmar Revogação</DialogTitle>
              <DialogContent>
                <DialogContentText>
                    Tem certeza que quer revogar esse certificado?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmationDialogOpen(false)} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={revokeCertificate} color="error">
                    Sim, revogar esse certificado.
                </Button>
              </DialogActions>
            </Dialog>

            {/* Snackbar para mensagens de sucesso/erro */}
            <Snackbar
              open={!!message.text}
              autoHideDuration={6000}
              onClose={() => setMessage({ text: '', type: '' })}
              message={message.text}
            />
          </TableContainer>
        </TableContainer>
    </>
  );
}

DashboardTable.propTypes = {
  isLoading: PropTypes.bool,
  searchTerm: PropTypes.string,
};

export default DashboardTable;
