import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import sendLogToCloudWatch from '../../services/logger';
import Header from '../MainLayout/Header';
import Sidebar from '../MainLayout/Sidebar';
import { getUserAgentInfo } from '../../utils';
import DashboardTable from './DashboardTable';

function DashboardPage() {
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const userAgentInfo = getUserAgentInfo();
    const browser = userAgentInfo.browser.name || 'desconhecido';
    const os = userAgentInfo.os.name || 'desconhecido';

    sendLogToCloudWatch('info',`Usuário ${user.username} acessou a página principal usando o navegador ${browser} no dispositivo ${os}`);
    sendLogToCloudWatch('info',`Usuário ${user.username} é administrador: ${user.isAdmin}`);
  
  }, [user]);

  return (
    <>
      <Header />
      <Sidebar />
      <main>
        <div className='page-content'>
          {user ? <DashboardTable /> : <p>Carregando...</p>}
        </div>
      </main>
    </>
  );
}

export default DashboardPage;
