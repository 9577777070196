import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function Download({ isLoading, certificateData }) {
  const user = useSelector((state) => state.auth.user);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const downloadCertificate = async () => {
    setIsButtonDisabled(true);
    setDownloadError(null);

    try {
      const { id } = certificateData;
      if (!id || !user.customer_id) {
        throw new Error('ID or customer_id not found');
      }

      const payload = {
        customer_id: user.customer_id,
        order_number: id,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/provider/certificate/collect`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      let base64Response = await response.text();

      base64Response = base64Response.replace(/(\r\n|\n|\r)/gm, '').replace(/\s+/g, '');

      const blob = base64ToBlob(base64Response, 'application/zip');
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${certificateData.id || 'certificate'}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      setDownloadSuccess(true);
      setSuccessDialogOpen(true); // Open success dialog
    } catch (error) {
      setDownloadError(error.message);
      setErrorDialogOpen(true); // Open the error dialog
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
    try {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteArray = new Uint8Array(Array.from(slice).map(char => char.charCodeAt(0)));
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    } catch (e) {
      throw new Error("Failed to decode base64 string");
    }
  };

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <IconButton
            color="primary"
            aria-label="download"
            onClick={downloadCertificate}
            disabled={isButtonDisabled}
          >
            <CloudDownloadIcon />
          </IconButton>

          {/* Error Dialog */}
          <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
            <DialogTitle>Erro no Download</DialogTitle>
            <DialogContent>
              <Typography color="error">Download falhou: {downloadError}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setErrorDialogOpen(false)} color="primary">Fechar</Button>
            </DialogActions>
          </Dialog>

          {/* Success Dialog */}
          <Dialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
            <DialogTitle>Download com sucesso</DialogTitle>
            <DialogContent>
              <Typography>Download realizado!</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSuccessDialogOpen(false)} color="primary">Fechar</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}

Download.propTypes = {
  isLoading: PropTypes.bool,
  certificateData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    customer_id: PropTypes.string,
  }).isRequired,
};

export default Download;
