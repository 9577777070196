import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Dialog, DialogContent, DialogTitle, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../MainLayout/Header';
import Sidebar from '../MainLayout/Sidebar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import sendLogToCloudWatch from '../../services/logger';

function ChangePassword() {
    const user = useSelector((state) => state.auth.user);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);
    const [apiResponse, setApiResponse] = useState(null);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };
    
    const handleConfirmNewPasswordChange = (event) => {
        setConfirmNewPassword(event.target.value);
    };

    const toggleShowPassword = (type) => {
        switch (type) {
            case 'oldPassword':
                setShowOldPassword(!showOldPassword);
                break;
            case 'newPassword':
                setShowNewPassword(!showNewPassword);
                break;
            case 'confirmNewPassword':
                setShowConfirmNewPassword(!showConfirmNewPassword);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const apiUrl = 'https://api.trustcert.com.br/v1/user/change-pass';

            const requestBody = JSON.stringify({
                email: user.username,
                password: newPassword,
            });

            const headers = {
                'Content-Type': 'application/json',
            };

            const response = await fetch(apiUrl, {
                method: 'PUT',
                headers: headers,
                body: requestBody,
            });

            //console.info(`API request: ${requestBody}`);

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const responseData = await response.json();
            console.info(`API response: ${JSON.stringify(responseData)}`);
            setApiResponse(responseData);

            // Clear the password fields after success
            setOldPassword('');
            setNewPassword('');
            setConfirmNewPassword('');

            // Open the success dialog
            setIsSuccessDialogOpen(true);
        } catch (error) {
            sendLogToCloudWatch('error', 'API request error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Header />
            <Sidebar />
            <main>
                <div className='page-content'>
                    <Box>
                        <Typography variant="h4">Alterar Senha</Typography>

                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                type={showOldPassword ? 'text' : 'password'}
                                label="Senha Antiga"
                                variant="outlined"
                                value={oldPassword}
                                onChange={handleOldPasswordChange}
                                sx={{ mt: 2 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => toggleShowPassword('oldPassword')}
                                                edge="end"
                                            >
                                                {showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                fullWidth
                                type={showNewPassword ? 'text' : 'password'}
                                label="Nova Senha"
                                variant="outlined"
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                sx={{ mt: 2 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => toggleShowPassword('newPassword')}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                fullWidth
                                type={showConfirmNewPassword ? 'text' : 'password'}
                                label="Confirmar Nova Senha"
                                variant="outlined"
                                value={confirmNewPassword}
                                onChange={handleConfirmNewPasswordChange}
                                sx={{ mt: 2 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => toggleShowPassword('confirmNewPassword')}
                                                edge="end"
                                            >
                                                {showConfirmNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            {passwordValidationErrors.length > 0 && (
                                <Box mt={2}>
                                    <Typography variant="h6">Erro:</Typography>
                                    <ul>
                                        {passwordValidationErrors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </Box>
                            )}

                          <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
                              {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Enviar'}
                          </Button>
                        </form>

                        <Dialog open={isSuccessDialogOpen} onClose={() => setIsSuccessDialogOpen(false)}>
                            <DialogTitle>Successo</DialogTitle>
                            <DialogContent>
                                <Typography variant="body1">Senha Alterada com Sucesso!</Typography>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </div>
            </main>
        </>
    );
}

export default ChangePassword;
