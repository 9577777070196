import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { logoutAsync } from '../../services/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { IconButton, Avatar, Box, List, ListItemButton, ListItemIcon, ListItemText, Typography, Drawer, Divider } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { AccountBox, Logout } from '@mui/icons-material';
import { styled } from '@mui/system';
import { default as Sidebar } from './Sidebar';
import NotificationSection from './NotificationSection';
import PasswordIcon from '@mui/icons-material/Password';
import sendLogToCloudWatch from '../../services/logger';

const StyledHeader = styled('header')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  borderBottom: '1px solid lightgray',
  display: 'flex',
  alignItems: 'center',
  padding: '5px 10px',
  background: '#003251',
  zIndex: 1000,
});

function Header({ window }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const settingsAnchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);


  const handleLogout = () => {
    const logMessage = `Usuário ${user?.username} fez logout`;
    sendLogToCloudWatch('info',logMessage);

    dispatch(logoutAsync());
    navigate('/login'); 
  };

  const currentHour = new Date().getHours();
  let greeting;
  if (currentHour < 12) {
    greeting = 'Bom dia';
  } else if (currentHour < 18) {
    greeting = 'Boa tarde';
  } else {
    greeting = 'Boa noite';
  }

  const handleToggleNotification = () => {
    setOpenNotification(!openNotification);
  };

  const handleToggleSettings = () => {
    sendLogToCloudWatch('info',`Usuário ${user?.username} clicou em Configurações`)
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleRedirectAndClose = (path) => {
    setOpen(false);
    navigate(path);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={{ display: 'flex', ml: 'auto', alignItems: 'center' }}>
          {/* NotificationButton */}
          <NotificationSection notifyOpen={openNotification} handleToggleNotification={handleToggleNotification} 
          onExpiringSoonChange={(hasExpiringCertificates) => {
            sendLogToCloudWatch('info',"Expiring soon:", hasExpiringCertificates);}} />
          {/* SettingsMenu */}
          <IconButton ref={settingsAnchorRef} onClick={handleToggleSettings}>
            <Avatar
              variant="rounded"
              sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                transition: 'all .2s ease-in-out',
                backgroundColor: '#003251',
              }}
            >
              <SettingsIcon size="20" className="header-icon" />
            </Avatar>
          </IconButton>
          {/* SettingsMenu pop-up */}
          <Drawer
            anchor="right"
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { width: '260px' } }}
          >
            <Box sx={{ p: 2 }}>
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {greeting},
                </Typography>
                <Typography component="p" variant="subtitle2" sx={{ fontSize: 18 }}>
                  {user?.username}
                </Typography>
                <Typography component="p"  variant="subtitle2" sx={{ fontSize: 12 }}>
                  {user.company || 'Empresa'}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <List sx={{ minWidth: 100 }}>
                {/* Alterar Senha */}
                <ListItemButton sx={{ borderRadius: 1 }} onClick={() => handleRedirectAndClose('/change-password')}>
                  <ListItemIcon>
                    <PasswordIcon className='menuIcon' size="30" />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body2">Alterar Senha</Typography>} />
                </ListItemButton>
                {/* Gestão de Usuários */}
                <ListItemButton sx={{ borderRadius: 1 }} onClick={() => handleRedirectAndClose('/users')}>
                  <ListItemIcon>
                    <AccountBox className='menuIcon' size="30" />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body2">Gerenciar Usuários</Typography>} />
                </ListItemButton>
                {/* Gestão de Clientes */}
                <ListItemButton sx={{ borderRadius: 1 }} onClick={() => handleRedirectAndClose('/customers')}>
                  <ListItemIcon>
                    <ApartmentIcon className='menuIcon' size="30" />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body2">Gerenciar Contas</Typography>} />
                </ListItemButton>
              </List>
              <Divider sx={{ my: 2 }} />
              {/* Botão de logout */}
              <List sx={{ minWidth: 100 }}>
                <ListItemButton sx={{ borderRadius: 1 }} onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout className='menuIcon' size="30" />
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="body2">Sair</Typography>} />
                </ListItemButton>
              </List>
            </Box>
          </Drawer>
        </Box>
      </StyledHeader>
      <Sidebar />
    </>
  );
}

Header.propTypes = {
  window: PropTypes.object,
};

export default Header;